<template>
  <multiselect
    :id="id"
    v-model="select"
    :options="completionsSelection"
    :searchable="true"
    :show-labels="false"
    :internal-search="false"
    :loading="$apollo.queries.completions.loading"
    :preserve-search="true"
    :show-no-options="false"
    :placeholder="placeholder"
    :reset-after="true"
    show-empty="false"
    track-by="name"
    @search-change="fetchElements"
    @select="triggerSelected"
  >
    <!--
      Pass through all slots to the multiselect component.
      From https://stackoverflow.com/a/50892881/185596
    -->
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
      ><slot :name="slot" v-bind="scope"
    /></template>
  </multiselect>
</template>

<script>
export default {
  name: "AutoComplete",
  props: {
    id: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: "Skriv något"
    },
    graphQuery: {
      type: Object,
      default: null
    },
    /* 
      A custom mapping function to map completions objects to the form 
      { name: "something" } if the completions objects are complex,
      like for example the signum objects which have signum1 and signum2
    */
    map: {
      type: Function,
      default: e => e
    }
  },
  data() {
    return {
      filter: "",
      select: "",
      completions: [],
      loading: true
    };
  },
  computed: {
    completionsSelection() {
      return this.completions.map(this.map).sort(this.sortByName);
    }
  },
  methods: {
    triggerSelected(input) {
      this.$emit("select", input);
    },
    fetchElements(query) {
      this.filter = query;
    },
    sortByName(o1, o2) {
      let name1 = o1 && o1.name ? o1.name : null;
      let name2 = o2 && o2.name ? o2.name : null;

      if (name1 !== null && name2 === null) {
        return 1;
      } else if (name1 === null && name2 !== null) {
        return -1;
      } else if (name1 === null && name2 === null) {
        return 0;
      } else {
        return name1.localeCompare(name2, "sv");
      }
    }
  },
  apollo: {
    completions: {
      query() {
        return this.graphQuery;
      },
      variables() {
        return {
          search: this.filter
        };
      }
    }
  }
};
</script>
